import gql from "graphql-tag";

const EmployeeFragment = gql`
  fragment employeeFragment on Employee {
    id
    firstName
    lastName
    profile {
      imageUrl
    }
  }
`;

export const FilterWithPaginateEmployees = gql`
  query FilterWithPaginateEmployees($criteria: EmployeeFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateEmployees(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
      }
      content {
        id
        firstName
        lastName
        workEmail
        profile {
          imageUrl
        }
        personal {
          birthDate
        }
        employment {
          passive
          staffNumber
          hiredDate
          passive
          branch {
            name
          }
          unit {
            name
          }
          team {
            name
          }
          jobInfo {
            name
          }
        }
        management {
          manager {
            ...employeeFragment
          }
        }
      }
    }
  }
  ${EmployeeFragment}
`;

export const Employee = gql`
  query Employee($id: ID!) {
    employee(id: $id) {
      id
      firstName
      lastName
      workEmail
      profile {
        imageUrl
      }
      personal {
        birthDate
      }
      employment {
        passive
        staffNumber
        hiredDate
        passive
        branch {
          name
        }
        unit {
          name
        }
        team {
          name
        }
        jobInfo {
          name
        }
      }
      management {
        manager {
          ...employeeFragment
        }
        hrManagers {
          manager {
            ...employeeFragment
          }
        }
      }
      leaveSettings {
        leaveManagers {
          manager {
            ...employeeFragment
          }
        }
      }
      timeSettings {
        shiftManagers {
          manager {
            ...employeeFragment
          }
        }
        overtimeManagers {
          manager {
            ...employeeFragment
          }
        }
      }
    }
  }
  ${EmployeeFragment}
`;

export const LeaveAccruals = gql`
  query leaveAccruals($employee: ID!, $onlyRequestable: Boolean) {
    leaveAccruals(employee: $employee, onlyRequestable: $onlyRequestable) {
      used
      previousBalance
      leavePolicy {
        accrualCount
        leaveType {
          id
          name
          icon
          color
          englishName
        }
      }
    }
  }
`;
