<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-row class="align-center">
            <v-col cols="12" sm="4" lg="3">
              <employee-picker
                v-model="table.criteria.id"
                :external-criteria="{ leaveManager: $store.state.auth.user.id }"
              />
            </v-col>
            <v-col cols="12" sm="8" lg="9" class="text-right">
              <id-button @click="onRefreshData">
                <template #icon>
                  <v-icon left>mdi-refresh</v-icon>
                </template>
                {{ $t("buttons.refresh") }}
              </id-button>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          v-model="table.selected"
          class="elevation-1"
          :headers="table.headers"
          :items="table.items"
          :options.sync="table.options"
          :server-items-length="table.serverItemsLength"
          :loading="table.loading"
          :footer-props="table.footerProps"
          :item-class="getItemClass"
          @click:row="onRowClick"
        >
          <template #item.firstName="props">
            <employee-profile :employee="props.item" />
          </template>
          <template #item.status="props">
            {{ props.item.passive ? $t("hr.employees.passive") : $t("hr.employees.active") }}
          </template>
          <template #item.manager="props">
            <employee-chip v-if="props.item.management.manager" :employee="props.item.management.manager" />
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view
            @closed:detail="table.selectedItem = null"
            @detail-loaded:success="table.selectedItem = $event"
          />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { FilterWithPaginateEmployees } from "./gql";

  export default {
    name: "Employees",
    components: {
      EmployeePicker: () => import("@/components/selectpickers/EmployeePicker"),
      EmployeeChip: () => import("./components/EmployeeChip")
    },
    data: vm => ({
      table: {
        loading: false,
        selected: [],
        items: [],
        serverItemsLength: 0,
        selectedItem: null,
        footerProps: { "items-per-page-options": [10, 25, 50, 100] },
        criteria: {
          leaveManager: vm.$store.state.auth.user.id,
          id: null
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ["firstName", "lastName"],
          sortDesc: [false, false]
        },
        headers: [
          {
            text: vm.$t("global.employee"),
            value: "firstName",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.email"),
            value: "workEmail",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.staffNumber"),
            value: "employment.staffNumber",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.branch"),
            value: "employment.branch.name",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.unit"),
            value: "employment.unit.name",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.team"),
            value: "employment.team.name",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.jobInfo"),
            value: "employment.jobInfo.name",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.status"),
            value: "status",
            align: "center",
            sortable: false,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.hiredDate"),
            value: "employment.hiredDate",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.birthDate"),
            value: "personal.birthDate",
            align: "start",
            sortable: true,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("hr.employees.manager"),
            value: "manager",
            align: "start",
            sortable: false,
            class: ["primary--text", "text-no-wrap"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      getItemClass(item) {
        return item.id === this.$helpers.get(this.table.selectedItem, "id") ? "selected-row" : null;
      },
      onRowClick(item) {
        this.table.selectedItem = item;
        this.navigateToDetail();
      },
      navigateToDetail() {
        let routerParams = {
          id: this.$helpers.encodeID(this.table.selectedItem.id)
        };
        this.$router.push({ name: "employees_detail", params: routerParams });
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: FilterWithPaginateEmployees,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.toPaginationCriteria(this.table.options)
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.items = data.filterWithPaginateEmployees.content || [];
              this.table.serverItemsLength = data.filterWithPaginateEmployees.pageInfo.totalElements || 0;
            }
          })
          .catch(console.error)
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>
